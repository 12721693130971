jQuery(document).ready(function($){
	$(window).scroll(function() {    
	    var scroll = $(window).scrollTop();

	    if (scroll >= 5) {
	        $(".header--top").removeClass("header--extended");
	        $(".navbar-astus").removeClass("navbar--extended");
	        $("body").removeClass("body--extended");
	    } else {
		    $(".header--top").addClass("header--extended");
		    $(".navbar-astus").addClass("navbar--extended");
		    $("body").addClass("body--extended");
	    }
	});
});