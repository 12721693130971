;(function ($, window, document, undefined) {
    $(function () {

        // Allow "hover" on all bootstrap dropdown menus, this is
        // mostly useful for the navbar, but will work elsewhere too

        $.fn.unBindBootstrapNavbarCollapse = function() {

            return this.each(function() {

                var collapse = $(this).find('.navbar-collapse');

                var onMouseIn = function() {
                    $(this).addClass('open');
                };

                var onMouseOut = function() {
                    $(this).removeClass('open');
                };

                collapse.find('.dropdown').hover(onMouseIn, onMouseOut);

                collapse.on('hide.bs.collapse', function() {
                    $(this).find('.dropdown').bind('mouseenter', onMouseIn).bind('mouseleave', onMouseOut);
                }).on('show.bs.collapse', function() {
                    $(this).find('.dropdown').unbind('mouseenter').unbind('mouseleave');
                });

            });
        };

        $('.navbar').unBindBootstrapNavbarCollapse();

        // Add Lightbox support to images
        // That is, assuming it's included into the theme
        
        if (typeof lity != 'undefined') {
            $(document).on('click', '[href$=".jpg"], [href$=".png"]', lity);
        }

        // ...

    });
})(jQuery, window, document);